import { transparentize, darken, readableColor } from 'polished';

import { FontFace, TrackAndTraceConfig, TrackAndTraceConfigQuery } from '~/types/Branding';

/**
 * Computes values of optional and UI-only properties of TrackAndTraceConfig.
 * Converts TrackAndTraceConfigQuery to TrackAndTraceConfig.
 */
export const computeBrandingProperties = (config: TrackAndTraceConfigQuery): TrackAndTraceConfig => {
  const selectedTagBackgroundColor = config.selectedTagBackgroundColor || config.primaryColor;
  const primaryLightColor = transparentize(0.9, config.primaryColor);

  return {
    ...config,
    primaryLightColor,
    buttonColor: config.buttonColor || config.primaryColor,
    buttonBackgroundColor: config.buttonBackgroundColor || primaryLightColor,
    tagColor: config.tagColor || config.primaryColor,
    tagBackgroundColor: config.tagBackgroundColor || primaryLightColor,
    selectedTagColor: config.selectedTagColor || readableColor(selectedTagBackgroundColor),
    selectedTagBackgroundColor,
    progressBarColor: config.progressBarColor || config.primaryColor,
    tagHoverBackgroundColor: config.tagBackgroundColor ? darken(0.02, config.tagBackgroundColor) : transparentize(0.8, config.primaryColor),
  };
};

/**
 * Maps TrackAndTraceConfig to CSS properties, 1:1 with properties in TrackAndTraceConfig.
 */
export const mapTrackAndTraceConfigToCssProps = (config: TrackAndTraceConfig): {[key: string]: any} => {
  return {
    '--primary-color': config.primaryColor,
    '--link-color': config.linkColor,
    '--header-color': config.headerColor,
    '--header-background-color': config.headerBackgroundColor,
    '--footer-color': config.footerColor,
    '--footer-background-color': config.footerBackgroundColor,
    '--button-color': config.buttonColor,
    '--button-background-color': config.buttonBackgroundColor,
    '--tag-color': config.tagColor,
    '--tag-background-color': config.tagBackgroundColor,
    '--selected-tag-color': config.selectedTagColor,
    '--selected-tag-background-color': config.selectedTagBackgroundColor,
    '--progress-bar-color': config.progressBarColor,
    '--primary-light-color': config.primaryLightColor,
    '--tag-hover-background-color': config.tagHoverBackgroundColor,
  };
};

/**
 * Generates CSS @font-face rules for custom fonts
 */
export const generateFontFace = (fontFace: FontFace, slug: string): string => {
  const {
    fontFamily,
    fontStyle,
    fontWeight,
    src,
  } = fontFace;

  // eslint-disable-next-line max-len
  const sources = src.map((source) => `url('${process.env.NEXT_PUBLIC_ASSETS_URL}/branding/eshop/${slug}/font/${source.filename}') format('${source.format}')`);

  return `
    @font-face {
      font-family: ${fontFamily};
      font-style: ${fontStyle};
      font-weight: ${fontWeight};
      src: ${sources.join(', ')};
    }
  `;
};
