import { createContext } from 'react';

import { Branding, EshopSettings, LocalizationConfig, TrackAndTraceConfigQuery } from '~/types/Branding';
import { computeBrandingProperties } from '~/lib/utils/branding';

export const defaultLocalizationConfig: LocalizationConfig = {};

export const defaultEshopSettings: EshopSettings = {
  name: '',
};

/**
 * Default stiteknabalik.cz TrackAndTrace config, used as a fallback.
 */
export const defaultTrackAndTraceConfig: TrackAndTraceConfigQuery = {
  primaryColor: '#3745FF',
  linkColor: '#3745FF',
  headerColor: '#343A40',
  headerBackgroundColor: '#FFFFFF',
  footerColor: '#343A40',
  footerBackgroundColor: '#FFFFFF',
  buttonColor: '#3745FF',
  buttonBackgroundColor: '#E4E6FE',
  tagColor: '#3745FF',
  tagBackgroundColor: '#E4E6FE',
  selectedTagColor: '#FFFFFF',
  selectedTagBackgroundColor: '#3745FF',
  progressBarColor: '#3745FF',
  enabledRating: false,
};

/**
 * Branding context initialized with default values.
 */
const BrandingContext = createContext<Branding>({
  trackAndTraceConfig: computeBrandingProperties(defaultTrackAndTraceConfig),
  eshopSettings: defaultEshopSettings,
  localizationConfig: defaultLocalizationConfig,
});

export default BrandingContext;
