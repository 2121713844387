import { useContext } from 'react';

import BrandingContext from '~/lib/BrandingContext';

import css from './ErrorState.module.scss';

type Props = {
  fullScreenHeight?: boolean;
  image: string;
  title: string;
  subtitle: string;
  buttonText?: string;
  imageHeight?: number;
  imageWidth?: number;
};

const ErrorState: React.FC<Props> = ({
  fullScreenHeight = false,
  image,
  title,
  subtitle,
  buttonText,
  imageHeight,
  imageWidth,
}) => {
  const { localizationConfig } = useContext(BrandingContext);
  const contactUrl = localizationConfig.homepageUrl ?? localizationConfig.contactUrl;

  return (
    <div className={`w-100 d-flex justify-content-center align-items-center ${css.errorContainer} ${fullScreenHeight && css.fullScreenHeight}`}>
      <div className="m-4 d-flex flex-column align-items-center">
        <img
          className={`mt-4 ${css.errorImg}`}
          src={image}
          alt="error"
          height={imageHeight}
          width={imageWidth}
        />

        <h1 className={`mt-4 mb-3 ${css.errorTitle}`}>
          {title}
        </h1>

        <p className={css.errorSubtitle}>
          {subtitle}
        </p>

        {buttonText && contactUrl && (
          <a href={contactUrl} className={`mt-3 mb-5 btn btn-flat ${css.errorBtn}`}>
            {buttonText}
          </a>
        )}
      </div>
    </div>
  );
};

export default ErrorState;
